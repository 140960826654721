import { useEffect } from "react";
import Router from "next/router";

const handleRouteChange = (url) => {
    window.dataLayer.push({ event: "pageview" });
};

const trackPageview = () => {
    useEffect(() => {
        Router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            Router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [Router.events]);
};

export const trackEvent = (event) => {
    useEffect(() => {
        window.dataLayer.push(event);
    }, []);
};

export default trackPageview;

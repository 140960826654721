import Hidden from 'Components/Atoms/Hidden';
import PlatformsImage from "Assets/Image/platforms.png";
import PlatformsMobileImage from "Assets/Image/platforms_mobile.png";
import Image from 'next/image';
import * as S from "./styles";

const Hero = ({title, headline, description, image, fields, ...props}) => {
    return (
        <S.Wrapper data-testid="Hero">
            <S.Row container justifyContent={'center'} alignItems={'center'}>
                <S.Col item xs={12} md={4}>
                    <S.Title>{title}</S.Title>
                    <S.Headline>{headline}</S.Headline>
                    <S.ImageMobileContainer>
                        <Image
                            src={image && image !== '' ? image : PlatformsMobileImage}
                            layout="fill"
                            objectFit="contain"
                            priority={true}
                            className="platform-image" alt="Platforms" />
                    </S.ImageMobileContainer>
                    <S.DescriptionContainer dangerouslySetInnerHTML={{__html: description}}></S.DescriptionContainer>
                </S.Col>
                <S.Col item xs={12} md={5} className={"hide-mobile"}>
                    <S.ImageContainer height={image && image !== '' ? 'false' : 'true'}>
                        <Hidden smDown>
                            <Image
                                src={image && image !== '' ? image : PlatformsImage}
                                layout="fill"
                                objectFit="contain"
                                priority={true}
                                className="platform-image" alt="Platforms" />
                        </Hidden>
                    </S.ImageContainer>
                </S.Col>
            </S.Row>
        </S.Wrapper>
    );
};

export default Hero;

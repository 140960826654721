import styled from 'styled-components';
import Grid from "Components/Atoms/Grid";

export const Col = styled(Grid)``;
export const Row = styled(Grid)``;

export const Wrapper = styled.div`
    display: flex;
    background-color: #EDF2F4;
    padding: 20px 0px;
    border-bottom: 2px solid #AA1CC7;

    .platform-image {
        height: auto;
        max-width: 100%;
    }

    .hide-mobile {
        display: none;

        @media ${(props) => props.theme.device.laptop} {
            display: block;
        }
    }

    @media ${(props) => props.theme.device.laptop} {
        padding: 30px 15px;
        padding-left: 40px;
    }
`

export const Title = styled.h2`
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.2rem;
    color: #7900CC;
    text-align: center;

    @media ${(props) => props.theme.device.laptop} {
        margin-bottom: 25px;
        text-align: left;
    }
`;

export const Headline = styled.h3`
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 1.5;
    color: #6D6D6D;
    text-transform: lowercase;
    text-align: center;
    margin-bottom: 10px;

    @media ${(props) => props.theme.device.laptop} {
        color: #7900CC;
        text-transform: uppercase;
        text-align: left;
        margin-bottom: 30px;
    }
`;

export const ImageContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: ${({height}) => height === 'true' ? '40vh' : '35vh'};
`;

export const ImageMobileContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-height: 25vh;

    @media ${(props) => props.theme.device.laptop} {
        display: none;
    }
`;

export const DescriptionContainer = styled.div`
    display: flex;
    justify-content: center;

    @media ${(props) => props.theme.device.laptop} {
        justify-content: flex-start;
    }

    ul {
        display: inline-block;
        color: #575757;
        list-style: disc;
        margin-left: 15px;

        li {
            font-family: sans-serif;
            font-size: 1.2rem;
            line-height: 2rem;
        }
    }
`;

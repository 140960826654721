import styled from 'styled-components'
import Grid from "Components/Atoms/Grid";
import dynamic from 'next/dynamic';

const DynamicPlanFields = dynamic(() => import('Components/Molecules/CustomerPlanFields'));
const DynamicDataFields = dynamic(() => import('Components/Molecules/CustomerDataFields'));
const DynamicCardFields = dynamic(() => import('Components/Molecules/CustomerCardFields'));

export const Col = styled(Grid)``;
export const Row = styled(Grid)``;

export const Wrapper = styled.div`
    padding-top: 30px;
    margin-bottom: 20px;

    .side-divisors {
        @media ${(props) => props.theme.device.laptop} {
            border-left: 1px solid #AF1DC8;
            border-right: 1px solid #AF1DC8;
        }
    }
`

export const CustomPlanFields = styled(DynamicPlanFields)``;
export const CustomDataFields = styled(DynamicDataFields)``;
export const CustomCardFields = styled(DynamicCardFields)``;

import styled from 'styled-components';
import Grid from "Components/Atoms/Grid";
import dynamic from 'next/dynamic';


export const Col = styled(Grid)``;
export const Row = styled(Grid)``;

export const Wrapper = styled.div`
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 9;
`;

export const WhatsappButton = styled.div`
    background-color:#25d366;
    border-radius:50px;
    box-shadow:2px 2px 3px #999;
    padding: 10px 8px 8px 10px;
`;

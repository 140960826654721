import styled from 'styled-components';
import Grid from "Components/Atoms/Grid";
import Hero from "Components/Molecules/Hero";
import SubscriptionForm from "Components/Organisms/SubscriptionForm";

export const Col = styled(Grid)``;
export const Row = styled(Grid)``;

export const SectionHero = styled.div``

export const CustomHero = styled(Hero)``;

export const SectionForm = styled.div``

export const CustomSubscriptionForm = styled(SubscriptionForm)``;

export function getApiURL() {
    // return "http://exercicioemcasa-api-old.ddev.site/api";
    return "https://exercicioemcasa-api-staging.herokuapp.com/api";
}

export const fetchAllSales = () => {
    return fetch(`${getApiURL()}/checkout/all`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        }
    });
}

export const fetchSale = (sale) => {
    return fetch(`${getApiURL()}/checkout-info?checkoutPage=${sale}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        }
    });
}

export const fetchDefaultSale = () => {
    return fetch(`${getApiURL()}/checkout-info`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        }
    });
}

export const fetchPlans = () => {
    return fetch(`${getApiURL()}/plans`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        }
    });
}

export const fetchTestVoucher = (voucher) => {
    return fetch(`${getApiURL()}/voucher/${voucher}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        }
    });
}

export const fetchGalaxCard = (card) => {
    const payload = {
      "Request": card,
    };

    return fetch("https://app.galaxpay.com.br/webservice/tokenizeCard", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(payload)
    });
}

export const fetchSubscription = (payload) => {
    return fetch(`${getApiURL()}/checkout`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(payload)
    });
}

export const fetchOneClickPurchase = (payload) => {
    return fetch(`${getApiURL()}/checkout-one-click`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(payload)
    });
}

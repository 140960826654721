import {useRouter} from "next/router";

export default function useCampaignName() {
    const router = useRouter();

    const hasCampaign = router.asPath.startsWith('/campanha/');
    let campaignName = null;

    if (hasCampaign) {
        campaignName = router.asPath.split('/')[2] ?? null;
    }

    return {
        hasCampaign,
        campaignName,
    }
}

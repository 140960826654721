const validationMessages = {
    mixed: {
        default: 'Não é válido',
        required: '*Campo obrigatório',
        oneOf: 'Deve ser uma das opções: ${values}',
        notOneOf: 'Não pode ser uma das opções: ${values}',
        notType: 'Formato inválido',
    },
    string: {
        length: 'Deve ter exatamente ${length} caracteres',
        min: 'No mínimo ${min} caracteres',
        max: 'No máximo ${max} caracteres',
        matches: 'Formato inváĺido',
        email: 'Email inválido',
        url: 'Url inválida'
    }
};

export default validationMessages;

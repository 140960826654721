import { loadMercadoPago } from '@mercadopago/sdk-js';

export class MercadoPagoInstance {
    static publicKey = null;
    static options = {};
    static instanceMercadoPago = undefined;
    static loadedInstanceMercadoPago = false;

    static async getInstance() {
        if (this.publicKey) {
            if (!this.loadedInstanceMercadoPago) {
                await loadMercadoPago();
                this.loadedInstanceMercadoPago = true;
            }
            if (!this.instanceMercadoPago) {
                this.instanceMercadoPago = new window.MercadoPago(this.publicKey, this.options);
            }
            return this.instanceMercadoPago;
        } else {
            console.error('Expected the PUBLIC_KEY to render the MercadoPago SDK React');
        }
    }
}

/**
 * Create an instance of MercadoPago
 * @param publicKey string
 * @param options TOptions
 */
const initMercadoPago = (publicKey, options) => {
    const injectFrontEndOption = { ...options, frontEndStack: 'react' };

    if (publicKey !== MercadoPagoInstance.publicKey) {
        MercadoPagoInstance.publicKey = publicKey;
        MercadoPagoInstance.options = injectFrontEndOption;
        MercadoPagoInstance.instanceMercadoPago = undefined;
    }
};

export default initMercadoPago;

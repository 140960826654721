import React, { useRef, useState, useEffect, useCallback } from "react";
import { Form } from "@unform/web";
import { dataFormValidation } from "Utils/validation/formValidationSchemas";
import { fetchGalaxCard, fetchSubscription } from "Features/Api";
import { parse, format, set } from "date-fns";
import { useRouter } from "next/router";
import * as Yup from "yup";
import * as S from "./styles";
import { getEventName } from "../../../Utils/events";
import useCampaignName from "../../../Utils/hooks/useCampaignName";
import { initMercadoPago, createCardToken, getPaymentMethods } from '../../../Utils/mercadopago';

initMercadoPago("APP_USR-119da67e-ce0a-4959-8090-eaefce3129c0", {
    locale: "pt-BR",
})

const SubscriptionForm = ({
    checkout = null,
    gateway = "galaxpay",
    baseRoute = "",
    items,
    addons,
    testimonials,
    ...props
}) => {
    const formRef = useRef(null);
    const router = useRouter();
    const campaign = useCampaignName();
    const [loader, setLoader] = useState(false);
    const [redirectTo, setRedirectTo] = useState(null);
    const [fields, setFields] = useState({
        plan_id: items.length > 0 ? items[0].id : null,
        addons: [],
        name: "",
        phone: "",
        email: "",
        cpf: "",
        password: "",
        check_already_registered: true,
        voucher: "",
        checkoutPage: checkout,
        card_name: "",
        card_number: "",
        card_expires: "",
        card_code: "",
        card_brand: "",
    });

    const validateField = useCallback((field) => {
        try {
            dataFormValidation.validateSyncAt(field, fields, {
                abortEarly: false,
            });

            formRef.current.setFieldError(field, false);
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                formRef.current.setFieldError(
                    err.path,
                    err.errors[err.errors.length - 1]
                );
            }
        }
    });

    const updateFieldsFromQuery = useCallback(() => {
        const setWithTime = setTimeout(() => {
            if (fields.phone.length) return;
            if (fields.name.length) return;
            const params = router.query;
            const newFields = { ...fields };

            for (const param in params) {
                if (Object.prototype.hasOwnProperty.call(params, param)) {
                    if (param === "celular") {
                        const maskFormat = params[param].replace(
                            /^(\d{2})(\d{4,5})(\d{4})$/,
                            "$1 $2-$3"
                        );
                        newFields["phone"] = maskFormat;
                    }
                    newFields[param] = params[param];
                }
            }
            setFields({ ...newFields });
        }, 500);

        return () => {
            clearTimeout(setWithTime);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);

    useEffect(() => {
        updateFieldsFromQuery();

        if (redirectTo !== null) {
            router.push(baseRoute + "" + redirectTo);
        }
    }, [updateFieldsFromQuery, redirectTo, router, baseRoute]);

    const handleFieldChange = async (field, value) => {
        if (field.includes("addon")) {
            let attr = field.split(".");
            let addonId = parseInt(value);

            fields["addons"].splice(attr[1], 1);
            if (addonId > 0) fields["addons"][attr[1]] = addonId;
        } else {
            fields[field] = value;
        }
        setFields({ ...fields });
        validateField(field);
    };

    const handleEventChange = (event) => {
        handleFieldChange(event.target.name, event.target.value);
    };

    const handleEventBlur = async (event) => {
        validateField(event.target.name);
    };

    const rdStationIntegration = () => {
        if (typeof RdIntegration !== "undefined") {
            const {
                card_name,
                card_number,
                card_expires,
                card_code,
                card_brand,
                password,
                cpf,
                ...payload
            } = fields;

            let rdPayload = [];
            for (let index in payload) {
                rdPayload.push({ name: index, value: payload[index] });
            }

            const rdToken = "fbdf1b5aace3186529f44ece4579d5b4";
            const url = window.location.href.split("?")[0];

            rdPayload.push({ name: "identificador", value: "assinatura-base" });
            rdPayload.push({ name: "token_rdstation", value: rdToken });
            rdPayload.push({ name: "form_url", value: url });

            RdIntegration.post(
                rdPayload,
                (e) => {
                    LeadTracking.send({
                        url: url,
                        token: rdToken,
                    });
                },
                10
            );
        }
    };

    function proccessPayment(payload) {
        rdStationIntegration();
        fetchSubscription(payload)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 200) {
                    let success = {
                        checkout: getEventName(
                            "sucesso",
                            checkout
                        ),
                        event_name: json.data.event_name,
                        extra: json.data.extra,
                        title: "Recebemos Seu Pedido",
                        message:
                            "Pronto! Agora é só esperar a confirmação do pagamento para seu acesso ser liberado. Se tiver alguma dúvida ou quiser falar conosco basta entrar em contato pelo suporte.",
                    };
                    console.log(success, json);

                    console.log("success");
                    sessionStorage.setItem(
                        "feedback_data",
                        JSON.stringify(success)
                    );

                    setRedirectTo(
                        "/finalizar-compra/order-received-app"
                    );
                } else {
                    console.log("error", json);

                    let errors = "";
                    if (
                        json.data &&
                        json.data.hasOwnProperty("validation")
                    ) {
                        Object.entries(
                            json.data.validation
                        ).forEach(([key, value]) => {
                            errors += `${value}&nbsp;`;
                        });
                    }

                    let error = {
                        title: "Error! " + json.status,
                        message:
                            "Ops aconteceu um erro &nbsp;" +
                            errors,
                    };

                    sessionStorage.setItem(
                        "feedback_data",
                        JSON.stringify(error)
                    );
                    if (window.dataLayer) {
                        window.dataLayer.push(
                            getEventName("erro", checkout)
                        );
                    }
                    setRedirectTo("/finalizar-compra/erro");
                }
            })
            .catch((e) => {
                console.log("error", e);
            });
    }

    async function handleSubmit() {
        setLoader(true);

        try {
            formRef.current.setErrors({});
            await dataFormValidation.validate(fields, {
                abortEarly: false,
            });

            const {
                card_name,
                card_number,
                card_expires,
                card_code,
                card_brand,
                ...payload
            } = fields;

            let expirationMonth = format(
                parse(card_expires, "MM/yyyy", new Date()),
                "MM"
            );
            let expirationYear = format(
                parse(card_expires, "MM/yyyy", new Date()),
                "yyyy"
            );

            if (campaign.hasCampaign) {
                payload.campaignName = campaign.campaignName;
            }

            payload.gateway_name = gateway;

            if (payload.gateway_name === 'mercadopago') {
                let cardData = {
                    cardNumber: card_number.replace(/\D/g, ''),
                    cardholderName: card_name,
                    cardExpirationMonth: expirationMonth,
                    cardExpirationYear: expirationYear,
                    securityCode: card_code,
                    identificationType: "CPF",
                    identificationNumber: payload.cpf.replace(/\D/g, ''),
                };

                const mp = new window.MercadoPago("APP_USR-119da67e-ce0a-4959-8090-eaefce3129c0", {
                    locale: "pt-BR",
                });

                const { results } = await getPaymentMethods({ bin: cardData.cardNumber.slice(0, 8) });
                const paymentMethod = results[0];

                payload.payment_method_id = paymentMethod.id;
                payload.issuer_id = paymentMethod.issuer.id;

                const token = await createCardToken(cardData);
                payload.gateway_token = token.id;
                proccessPayment(payload);
            } else {
                let cardData = {
                    holder: card_name,
                    expiryMonth: expirationMonth,
                    expiryYear: expirationYear,
                    number: card_number.replace(/\s/g, ""),
                    cvv: card_code,
                    payment_method_code: "credit_card",
                    brand: card_brand,
                };

                fetchGalaxCard(cardData)
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.hasOwnProperty("token")) {
                            payload.gateway_token = json.token;

                            proccessPayment(payload)
                        } else {
                            console.log("error", json);
                        }
                    })
                    .catch((e) => {
                        console.log("error", e);
                    });
            }
        } catch (err) {
            console.log(err)
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }

            setLoader(false);
        }
    }

    return (
        <S.Wrapper data-testid="SubscriptionForm">
            <Form ref={formRef} id={"assinatura-base"} onSubmit={handleSubmit}>
                <S.Row
                    container
                    justifyContent={"center"}
                    alignItems={"stretch"}
                >
                    <S.Col item xs={12} md={4}>
                        <S.CustomPlanFields
                            items={items}
                            addons={addons}
                            fields={fields}
                            onFieldChange={handleFieldChange}
                        />
                    </S.Col>
                    <S.Col item xs={12} md={4} className={"side-divisors"}>
                        <S.CustomDataFields
                            fields={fields}
                            onEventChange={handleEventChange}
                            onEventBlur={handleEventBlur}
                        />
                    </S.Col>
                    <S.Col item xs={12} md={4}>
                        <S.CustomCardFields
                            fields={fields}
                            loading={loader}
                            onFieldChange={handleFieldChange}
                            onEventChange={handleEventChange}
                            onEventBlur={handleEventBlur}
                        />
                    </S.Col>
                </S.Row>
            </Form>
        </S.Wrapper>
    );
};

export default SubscriptionForm;

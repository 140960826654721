import React from "react";
import LayoutPage from "Components/Organisms/LayoutPage";
import Seo from "Components/Atoms/Seo";
import WhatsappFloatingButton from "../../Components/Molecules/WhatsappFloatingButton";
import * as S from "./styles";

const PageHome = ({ customData, showHeaderTimer, baseRoute='' }) => {
    let gatewayName = 'galaxpay'
    if (customData.gateway) {
        gatewayName = customData.gateway
    }

    return (
        <LayoutPage customTimer={showHeaderTimer}>
            <Seo title={"Finalizar Assinatura - Exercício em Casa - A sua Academia Online"} />
            <WhatsappFloatingButton />
            <S.SectionHero>
                <S.CustomHero
                    title={customData.title}
                    headline={customData.headline}
                    description={customData.description}
                    image={customData.imageUri} />
            </S.SectionHero>
            <S.SectionForm>
                <S.CustomSubscriptionForm baseRoute={baseRoute} checkout={customData.slug} gateway={gatewayName} items={customData.items} addons={customData.addons} testimonials={customData.testimonies} />
            </S.SectionForm>
        </LayoutPage>
    );
};
export default PageHome;

import * as Yup from "yup";
import { parse, isValid, toDate, isDate } from "date-fns";
import messages from "./validationMessages";

Yup.setLocale(messages)
Yup.addMethod(Yup.date, 'format', function (format) {
    return this.transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        // const parsedDate = isDate(originalValue)
        //   ? originalValue
        //   : parse(originalValue, format, new Date());

        value = parse(originalValue, format, new Date());

        return isValid(value) ? value : new Date('');
    });
});

export const dataFormValidation = Yup.object().shape({
    plan_id: Yup.number().min(1).required(),
    voucher: Yup.string(),
    name: Yup.string().required(),
    phone: Yup.string().matches(/^\d{2}\s\d{4,5}\-\d{4}$/).required(),
    email: Yup.string().email().required(),
    cpf: Yup.string().matches(/^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/).required(),
    password: Yup.string().min(8).required(),
    card_name: Yup.string().required(),
    card_number: Yup.string().min(16).required(),
    card_expires: Yup.string().matches(/^(0[1-9]|1[0-2])\/?([0-9]{4})$/, "A data deve ser no formato 99/9999 (ex: 01/2023)").required(),
    card_code: Yup.string().min(3).max(4).required(),
});
